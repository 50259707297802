import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout';
import PersonPage from 'components/PersonPage';


const Person = (props) => {
  return (
    <Layout
      withHeader
      page="arthur"
      isHome
      lang={_get(props, 'pageContext.lang', 'en')} registrationURL={_get(props, 'pageContext.registrationURL', '')}
    >
      <PersonPage name="arthur" />
    </Layout>
  );
}

export default Person;
